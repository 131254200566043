"use client";

import { usePathname } from "next-intl/client";
import { Button } from "../../ui/button";
import type { ModelsCategory } from "../../../types/strapi/models";
import type { Theme } from "../../../types/strapi/common";

interface NewsListCategoryLinkProps {
  theme: Theme;
  category: ModelsCategory;
  sectionName: string;
  anchor?: boolean;
}

function NewsListCategoryLink({
  theme,
  category,
  sectionName,
  anchor = false,
}: NewsListCategoryLinkProps) {
  const currentPath = usePathname();
  const { slug, name, path } = category;

  const active = currentPath === path;
  const colorByTheme = theme === "dark" ? "gray" : "grayDark";
  const href = anchor ? `#${slug}` : path;

  return (
    <Button
      appearance="solid"
      color={active ? "blueDark" : colorByTheme}
      href={href}
      sectionName={`${sectionName}_${name}`}
      size="xxs"
      title={name}
    >
      {name}
    </Button>
  );
}

export default NewsListCategoryLink;
