import { useMemo } from "react";
import type { SectionProps, CommonCard } from "../../../types";
import { slugify } from "../../../util/text";
import {
  moduleCardVariant,
  type ModuleCardVariantProps,
} from "./module-card.variants";

export type UseModuleCardProps = SectionProps<
  CommonCard,
  ModuleCardVariantProps
>;

export const useModuleCard = (props: UseModuleCardProps) => {
  const { className = "", theme = "dark", data, sectionName } = props;

  const styles = moduleCardVariant({ theme });

  const url = useMemo(() => {
    const slugifiedSectionName = slugify(`${sectionName}_${data.title}`);
    return data.url;
  }, [data.title, data.url, sectionName]);

  return {
    theme,
    className,
    sectionName,
    styles,
    data: {
      ...data,
      url,
    },
  };
};
